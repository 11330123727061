import gsap from "gsap";
import { Element } from "@/scripts/extends";

export default class Logos extends Element {
	mounted() {
		this.rows = this.el.querySelectorAll(".c-logos__row");

		this.animate();
	}

	animate() {
		this.rows.forEach((row, index) => {
			gsap.to(row, {
				scrollTrigger: {
					trigger: this.el,
					start: "top 100%",
					end: "top 0%",
					scrub: 3,
				},
				translateX: (index % 2 === 0) ? 200 : -200,
			});
		});
	}
}
